import { addMinutes, isAfter, isToday, isWeekend } from 'date-fns';
import { t } from 'i18next';
import { uniq } from 'lodash';
import { createSelector } from 'reselect';

import type { RankingParticipant } from '@edapp/leaderboards';
import { ErrorLogger } from '@edapp/monitoring';
import type { DictionaryType } from '@edapp/utils';
import { checkDeviceOnline } from '@maggie/cordova/network_utils';
import type { ThomasSlideInteraction } from '@maggie/core/lessons/thomas/thomas-interaction-types';
import { OfflineAssets } from '@maggie/core/offlineAssets';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { CourseSelectors } from '../courses/selectors';
import type { LessonSummaryType, ModuleType } from '../courses/types';
import type { LessonSummaryWithProgress } from '../lesson-activity/types';
import type { CompletionCriteria, SummaryListItem, UnlockPayload } from '../types';
import { CoursewareTypeNames } from '../types';
import { CoursewareUtils } from '../utils';
import type {
  LastLessonsScore,
  LessonAttemptType,
  LessonProgressType,
  LessonStatusType,
  LessonType
} from './types';

const getLesson = (id: string, state: LxStoreState): LessonType | undefined => {
  // Only show offline lesson if its fully downloaded
  if (
    !checkDeviceOnline() &&
    state.offline.status[id] &&
    state.offline.status[id].progress === 100
  ) {
    return OfflineAssets.normalizeUrls(state.offline.courseware.lessons.lessons[id]);
  } else {
    return state.courseware.lessons.lessons[id];
  }
};

const getLessonProgress = (id: string, state: LxStoreState): LessonProgressType | undefined => {
  return (
    state.offline.courseware.lessons.lessonsProgress[id] ||
    state.courseware.lessons.lessonsProgress[id]
  );
};

const getLessons = (state: LxStoreState): DictionaryType<LessonType> => {
  return checkDeviceOnline()
    ? state.courseware.lessons.lessons
    : state.offline.courseware.lessons.lessons;
};

const getLessonsProgress = (state: LxStoreState): DictionaryType<LessonProgressType> => {
  return checkDeviceOnline()
    ? state.courseware.lessons.lessonsProgress
    : state.offline.courseware.lessons.lessonsProgress;
};

const getLessonAttempt = (id: string) => (state: LxStoreState): LessonAttemptType | undefined => {
  return state.courseware.lessons.lessonsAttempts[id];
};

const getIsLessonInProgress = (id: string) => (state: LxStoreState) => {
  const attempt = getLessonAttempt(id)(state);
  return !!attempt?.attemptId && attempt.slides.length > 0;
};

const getCanLessonBeReviewed = (id: string) => (state: LxStoreState) => {
  const isInProgress = getIsLessonInProgress(id)(state);
  const progress = getLessonProgress(id, state);
  return !isInProgress && !!progress?.completed;
};

// TODO: https://safetyculture.atlassian.net/browse/TRAINING-529
const getCourseCompletion = (
  lessonSummaries: LessonSummaryType[],
  criteria: CompletionCriteria
) => (state: LxStoreState) => {
  const progress = getLessonsProgress(state);
  const availableLessonsProgress = lessonSummaries.map(l => progress[l.lessonId]).filter(l => !!l);

  const unlockedLessonsIds: string[] = [];
  const completedLessonsIds: string[] = [];
  const openedLessonsIds: string[] = [];

  for (const lessonProgress of availableLessonsProgress) {
    if (lessonProgress.unlocked) {
      unlockedLessonsIds.push(lessonProgress.lessonId);
    }
    if (lessonProgress.completed) {
      completedLessonsIds.push(lessonProgress.lessonId);
    }
    if (lessonProgress.opened) {
      openedLessonsIds.push(lessonProgress.lessonId);
    }
  }

  const totalNumLessons = lessonSummaries.length;
  // If criteria is openToComplete - we're calculating everything based on opened lessons
  const actionedLessonIds = criteria.openToComplete ? openedLessonsIds : completedLessonsIds;
  const numActionedLessonIds = actionedLessonIds.length;

  // Required Specific Lessons
  if (criteria.milestone === 'lessons' && criteria.lessonIds.length > 0) {
    const isCompleted = criteria.lessonIds.every(id => actionedLessonIds.includes(id));
    return {
      unlockedLessonsIds,
      completedLessonsIds,
      openedLessonsIds,
      isCompleted,
      percentageCompleted: isCompleted ? 1 : numActionedLessonIds / (totalNumLessons || 1)
    };
  }

  // Minimum Percentage
  const isCompleted =
    totalNumLessons === 0
      ? false
      : numActionedLessonIds >= (criteria.percentage * totalNumLessons) / 100;
  return {
    unlockedLessonsIds,
    completedLessonsIds,
    openedLessonsIds,
    isCompleted,
    percentageCompleted: numActionedLessonIds / (totalNumLessons || 1)
  };
};

const getNewScoreRankings = (
  lessonId: string,
  state: LxStoreState
): RankingParticipant[] | undefined => {
  const rankings: RankingParticipant[] | undefined =
    state.leaderboards.selectedLeaderboard?.rankings;
  const lastLessonsScore: LastLessonsScore = state.courseware.lessons.lastLessonsScore[lessonId];

  if (!rankings || rankings.length === 0) {
    return;
  }

  // New array so we can modify it without affecting the store
  const newRankings = rankings.filter(rank => rank.type === 'individual') || [];

  // If no individual results then we can return
  if (newRankings.length === 0) {
    return newRankings;
  }

  // If no score difference just return rankings (with only individual
  // Unless both 0 and we need to check if it was unattempted since we would need to update '-' to 0
  if (
    lastLessonsScore?.currBestScore === undefined ||
    (lastLessonsScore?.currBestScore <= lastLessonsScore?.prevBestScore &&
      !(lastLessonsScore?.currBestScore === 0 && lastLessonsScore?.prevBestScore === 0))
  ) {
    return newRankings;
  }

  const MAX_VISIBLE_RANK = 25;
  const scoreIncrease = lastLessonsScore.currBestScore - lastLessonsScore.prevBestScore;

  // If the user is already top dog we don't need to do much
  if (newRankings[0].isMe) {
    return [
      { ...newRankings[0], totalScore: newRankings[0].totalScore + scoreIncrease },
      ...newRankings.slice(1)
    ];
  }

  // When rank is over 25 we may need to estimate the new rank
  // For more explanation on this linear magic:
  // https://ed-app.atlassian.net/l/c/EVaVgUtF
  function getEstimatedRank(myRank: RankingParticipant) {
    const userRank = MAX_VISIBLE_RANK;
    const newScore = myRank.totalScore + scoreIncrease;
    const userScore = newRankings[MAX_VISIBLE_RANK - 1].totalScore;
    const currentRank = myRank.isNotAttempted ? myRank.totalParticipants : myRank.rank;
    const currentScore = myRank.totalScore;

    return Math.ceil(
      userRank + ((newScore - userScore) * (currentRank - userRank)) / (currentScore - userScore)
    );
  }

  // If rank new score falls within known scores we can get an accurate ranking
  function getAccurateRank(myRank: RankingParticipant) {
    const newScore = myRank.totalScore + scoreIncrease;
    let newRank: number | '-' = MAX_VISIBLE_RANK + 1;
    newRankings.find((rank, i) => {
      if (newScore > rank.totalScore || (newScore === rank.totalScore && rank.isNotAttempted)) {
        newRank = i + 1;
        return true;
      }
    });
    return newRank;
  }

  function updateRankingsFromRank(myRank: RankingParticipant) {
    let prevRank: RankingParticipant | undefined;

    const orderedRankings: RankingParticipant[] = [];
    // update rank of new ranking and shift following ranks down
    for (let i = 0; i < updatedRankings.length + 1; i++) {
      const rank = updatedRankings[i];
      if (i + 1 < myRank.rank) {
        orderedRankings.push(rank);
        continue;
      }
      if (i + 1 === myRank.rank) {
        prevRank = rank ? { ...rank, rank: rank.rank + 1 } : rank;
        orderedRankings.push(myRank);
        continue;
      }
      if (i + 1 > myRank.rank && prevRank !== undefined) {
        const tempRank: RankingParticipant | undefined = rank
          ? { ...rank, rank: rank.rank + 1 }
          : rank;
        orderedRankings.push(prevRank);
        prevRank = tempRank;
        continue;
      }
    }
    updatedRankings = orderedRankings;
  }

  // Find the isMe and get it's new ranking data
  // We filter it out of the updatedRankings so we can correctly place it later
  // Only ever one isMe in this context
  let newRanking: RankingParticipant | undefined;
  let updatedRankings: RankingParticipant[] = newRankings.filter((rank, i) => {
    if (!rank.isMe) {
      return true;
    }
    const newScore = rank.totalScore + scoreIncrease;
    // If the new score is still 26th place
    // then we need to estimate the new rank
    // as we only have at most 25 ranks in the rankings
    const newRank =
      i === MAX_VISIBLE_RANK && newScore < (newRankings[MAX_VISIBLE_RANK - 1].totalScore ?? 0)
        ? getEstimatedRank(rank)
        : getAccurateRank(rank);

    newRanking = {
      ...rank,
      rank: newRank,
      totalScore: newScore,
      isNotAttempted: false
    };
    return false;
  });
  // Place the new ranking in the correct place
  if (newRanking) {
    if (newRanking.rank > updatedRankings[updatedRankings.length - 1].rank) {
      updatedRankings = [...updatedRankings, newRanking];
    } else {
      // If the new rank falls within the known ranks we need to update the existing ranks too
      updateRankingsFromRank(newRanking);
    }
  }
  return updatedRankings;
};

// Returns next lesson id for the 'Next Lesson' button found on lesson and
// lesson-summary pages. Should return null if button is disabled in course
// settings or if next lesson is currently inaccessible to the learner.
const getNextAccessibleLessonFromCurrentLessonId = (
  currentLessonId: string,
  state: LxStoreState
) => {
  if (!currentLessonId) {
    return null;
  }

  const currentLesson = getLesson(currentLessonId, state);
  if (currentLesson == null) {
    return null;
  }

  const course = CourseSelectors.getCourse(currentLesson.courseId, state);
  if (course == null) {
    return null;
  }

  // relies on course.lessonSummaries to already be properly ordered by rank
  const currentLessonSummaryIndex = course.lessonSummaries.findIndex(
    lessonSummary => lessonSummary.lessonId === currentLessonId
  );
  if (currentLessonSummaryIndex === -1) {
    return null;
  }

  const nextLessonSummaryIndex = currentLessonSummaryIndex + 1;
  const nextLessonSummary = course.lessonSummaries[nextLessonSummaryIndex];
  if (nextLessonSummary == null) {
    return null;
  }

  // when offline, next lesson must have been downloaded
  const isOnline = checkDeviceOnline();
  if (!isOnline && !state.offline.courseware.lessons.lessons[nextLessonSummary.lessonId]) {
    return null;
  }

  if (!isLessonAvailable(nextLessonSummary, state)) {
    return null;
  }

  if (state.config.restrictToLessonScreen) {
    return null;
  }

  return nextLessonSummary;
};

const isLessonAvailable = (
  lesson: LessonSummaryType | LessonType | undefined,
  state: LxStoreState
) => {
  if (!lesson) {
    return false;
  }

  if (lesson.lessonType === 'Conference') {
    return true;
  }

  const lessonId =
    lesson.typeName === CoursewareTypeNames.LESSON_SUMMARY ? lesson.lessonId : lesson.id;

  const progress = getLessonProgress(lessonId, state);

  // If it's not a LESSON_SUMMARY, we assume dates are unlocked!
  const datesUnlocked =
    lesson.typeName === CoursewareTypeNames.LESSON_SUMMARY
      ? CoursewareUtils.isLessonInAvailableDates(
          lesson.planning.startDateTime,
          lesson.planning.endDateTime
        )
      : true;

  // If we haven't received progress yet, consider as unlocked
  const unlocked = progress ? !isLocked(lesson, progress, state) : !progress;

  return UserSelectors.isReviewer(state.user) || (unlocked && datesUnlocked);
};

const isLocked = (
  lesson: LessonSummaryType | LessonType,
  progress: LessonProgressType,
  state: LxStoreState
) => {
  return (
    !CoursewareUtils.isAvailableForPlatform(lesson) ||
    isLockedFromCompletion(lesson, progress) ||
    isLockedFromLastAttempt(lesson, progress) ||
    isLockedToday(progress, state) ||
    !progress.unlocked
  );
};

const isLockedFromCompletion = (
  lesson?: LessonSummaryType | LessonType,
  progress?: LessonProgressType
): boolean => {
  if (!lesson || !progress) {
    return false;
  }

  const attemptRestrictions = lesson.features.attemptRestrictionApiResource;
  return attemptRestrictions.shouldLockOnCompletion && progress.completed;
};

const isLockedFromLastAttempt = (
  lesson?: LessonSummaryType | LessonType,
  progress?: LessonProgressType
): boolean => {
  if (!lesson || !progress) {
    return false;
  }

  const lockIntervalMin = lesson.features.attemptRestrictionApiResource.lockIntervalMin || 0;
  if (lockIntervalMin === 0) {
    return false;
  }

  const lastAttemptFailed = progress.numberOfFailedAttempts > 0;
  if (!lastAttemptFailed || !progress.lastFailedAttemptDate) {
    return false;
  }

  const lockedUntil = addMinutes(new Date(progress.lastFailedAttemptDate), lockIntervalMin);
  return isAfter(lockedUntil, new Date());
};

// Locked because of nbLessonLimit per day or weekend forbidden
const isLockedToday = (progress: LessonProgressType | undefined, state: LxStoreState): string => {
  if (!progress) {
    return '';
  }

  const user = state.user;
  const ed = user.ed;
  if (!ed || UserSelectors.isReviewer(user)) {
    return '';
  }

  const { lessonOnWeekends, nbLessonsPerDay } = ed.content;

  const nbCompletionsToday = ed.lessonCompletedDates.reduce(
    (acc, date) => (isToday(date) ? acc + 1 : acc),
    0
  );

  if (!progress.completed && nbLessonsPerDay && nbCompletionsToday >= nbLessonsPerDay) {
    return 'limit';
  } else if (!lessonOnWeekends && isWeekend(new Date())) {
    return 'weekend';
  }

  return '';
};

const getLessonStatus = (lesson: LessonSummaryType, state: LxStoreState, courseId?: string) => {
  if (!CoursewareUtils.isAvailableForPlatform(lesson)) {
    return {
      id: 'platform-disabled',
      text: t(`lesson.status.platform-disabled`, { ns: 'learners-experience' })
    };
  }

  if (!isLessonAvailable(lesson, state)) {
    return {
      id: 'locked',
      text: t(`lesson.status.locked`, { ns: 'learners-experience' })
    };
  }

  return getLessonCompletionStatus(lesson, courseId, state);
};

/**
 * careful of using this, will always return a new object.
 */
const getProgressToCompletionStatus = (
  lesson: LessonSummaryType | LessonType,
  progress: LessonProgressType | undefined,
  courseId: string | undefined,
  state: LxStoreState
): LessonStatusType => {
  if (!progress) {
    return {
      id: 'new',
      text: t(`lesson.status.not-started`, { ns: 'learners-experience' })
    };
  }

  let courseRequiredLesson = false;
  if (courseId) {
    const completion = CourseSelectors.getCompletionCriteria(courseId, state);
    courseRequiredLesson = !!completion && completion.lessonIds.includes(progress.lessonId);
  }

  const lessonIsLocked = !isLessonAvailable(lesson, state);

  const isInProgress = getIsLessonInProgress(progress.lessonId)(state);

  switch (true) {
    case lessonIsLocked: {
      return {
        id: 'locked',
        text: t(`lesson.status.locked`, { ns: 'learners-experience' })
      };
    }

    case isInProgress: {
      return {
        id: 'in-progress',
        text: t('lesson.status.in-progress', { ns: 'learners-experience' })
      };
    }

    case progress.completed: {
      return {
        id: 'complete',
        text: t(`lesson.completions.completed`, { ns: 'learners-experience' })
      };
    }

    case courseRequiredLesson: {
      return {
        id: 'required',
        text: t(`lesson.status.required`, { ns: 'learners-experience' })
      };
    }

    default: {
      return {
        id: 'new',
        text: t(`lesson.status.not-started`, { ns: 'learners-experience' })
      };
    }
  }
};

const getLessonCompletionStatus = (
  lesson: LessonSummaryType | LessonType,
  courseId: string | undefined,
  state: LxStoreState
) => {
  const lessonId = (lesson as LessonType).id || (lesson as LessonSummaryType).lessonId;
  const progress = getLessonProgress(lessonId, state);
  return getProgressToCompletionStatus(lesson, progress, courseId, state);
};

const getFetchErrorCode = (state: LxStoreState) => {
  return state.courseware.lessons.fetchLessonErrorCode;
};

const getLessonCourseId = (lessonId: string) => {
  return (state: LxStoreState) => {
    return getLesson(lessonId, state)?.courseId;
  };
};

const getLessonCourse = (lessonId: string) => {
  return (state: LxStoreState) => {
    const courseId = getLesson(lessonId, state)?.courseId;
    if (!courseId) {
      return undefined;
    }
    return CourseSelectors.getCourse(courseId, state);
  };
};

/**
 *
 * This function will get all slides from a lesson.
 *
 * @param lessonId
 * @returns Array of SlideType
 */
const getLessonSlides = (lessonId: string) => {
  return (state: LxStoreState) => {
    const l = getLesson(lessonId, state);
    return l?.configuration.slides ?? [];
  };
};

const getFirstSlideId = (lessonId: string) => {
  return (state: LxStoreState) => {
    const slides = getLessonSlides(lessonId)(state);
    return slides[0]?.id;
  };
};

const getInteractionhSummaryItem = (interaction: ThomasSlideInteraction): SummaryListItem => {
  const question = interaction.slideData?.title || '';
  const takeaway = interaction.slideData?.answer?.takeaway || '';

  const answers = (interaction.slideData?.answers ?? []).map(({ content, correct }) => ({
    content: unescape(content || ''),
    correct: !!correct,
    userSelected: !!interaction.answerDetails?.find(({ answer }) => answer?.content == content)
  }));

  return {
    correct: !!interaction.correct,
    question: unescape(question),
    takeaway: unescape(takeaway),
    answers
  };
};

const getTitle = (state: LxStoreState) => {
  const lessonId = state.navigation.lessonId;
  const lesson = LessonSelectors.getLesson(lessonId, state);
  return lesson?.title || '';
};

const getDescription = (lessonId: string) => (state: LxStoreState) => {
  const lesson = LessonSelectors.getLesson(lessonId, state);
  return lesson?.description || '';
};

const getTextColor = (state: LxStoreState) => {
  const lessonId = state.navigation.lessonId;
  const lesson = LessonSelectors.getLesson(lessonId, state);
  return CoursewareUtils.getLessonStyleObject(lesson).text;
};

const getLessonPrerequisites = (lessonSummary?: LessonSummaryWithProgress) => (
  state: LxStoreState
): string[] => {
  if (!lessonSummary) {
    return [];
  }

  return lessonSummary.prerequisites.reduce((acc, prereq) => {
    const progress = LessonSelectors.getLessonProgress(prereq.id, state);
    if (!progress?.completed) {
      acc.push(prereq.title);
    }
    return acc;
  }, [] as string[]);
};

const getUnlockPayloadFromPrerequisites = (items: LessonSummaryType[]) => (state: LxStoreState) => {
  return items.map<UnlockPayload>(item => {
    return {
      id: item.lessonId,
      title: item.title,
      unlocked: item.prerequisites.reduce((all, pre) => {
        const progress = LessonSelectors.getLessonProgress(pre.id, state);
        return all && !!progress?.completed;
      }, true)
    };
  });
};

const getLessonMinimumScore = (lessonId: string) => (state: LxStoreState) => {
  const lesson = getLesson(lessonId, state);
  return lesson?.configuration.styleConfiguration.minimumScore || 0;
};

const getIsSummaryDialogOpen = (state: LxStoreState) => {
  return state.courseware.lessons.summaryDialogOpen;
};

const getLessonSummariesWithModules = createSelector(
  (state: LxStoreState, courseId: string) => CourseSelectors.getCourse(courseId, state),
  course => {
    if (!course) {
      return [];
    }
    return course.lessonSummaries.filter(lesson => lesson.moduleId);
  }
);

const findLessonSummary = (courseId: string, lessonId: string) => (state: LxStoreState) => {
  const summaries = getLessonSummariesWithoutModules(state, courseId);
  const lessonSummary = summaries.find(summary => summary.lessonId === lessonId);
  if (!!lessonSummary) {
    return lessonSummary;
  }

  const modules = getMemoizedModules(state);
  const moduleSummaries = Object.values(modules).reduce(
    (acc, module) => acc.concat(module.lessons),
    [] as LessonSummaryType[]
  );
  return moduleSummaries.find(summary => summary.lessonId === lessonId);
};

const getLessonSummariesWithoutModules = createSelector(
  (state: LxStoreState, courseId: string) => CourseSelectors.getCourse(courseId, state),
  course => {
    if (!course) {
      return [];
    }
    return course.lessonSummaries.filter(lesson => !lesson.moduleId);
  }
);

/**
 * @deprecated use getLessonSummariesWithModules or getLessonSummariesWithoutModules, especially in split view.
 * to get lesson progress use getLessonProgress
 */
const getMemoizedLessonsSummariesWithProgress = createSelector(
  (state: LxStoreState) => getLessonsProgress(state),
  (state: LxStoreState) => CourseSelectors.getCourse(state.navigation.course.id, state),
  (state: LxStoreState, withModules: boolean) => !!withModules,
  (lessonsProgress, course, withModules) => {
    if (!course) {
      return [];
    }

    return course.lessonSummaries
      .filter(lesson => (!!withModules ? !!lesson.moduleId : !lesson.moduleId))
      .map(lessonSummary => ({
        ...lessonSummary,
        progress: lessonsProgress[lessonSummary.lessonId]
      }));
  }
);

type ModuleDictionary = {
  [moduleId: string]: {
    module: ModuleType;
    lessons: LessonSummaryType[];
  };
};
const getMemoizedModules = createSelector(
  (state: LxStoreState) => CourseSelectors.getCourse(state.navigation.course.id, state),
  (state: LxStoreState) => getLessonSummariesWithModules(state, state.navigation.course.id),
  (course, lessons) => {
    const result: ModuleDictionary = {};

    if (!course) {
      return result;
    }

    // There is no rank for the lesson
    // There is no rank for the module
    // We will go through every lesson and get the order of the modules from those
    const modulesOrder = uniq(lessons.map(l => l.moduleId));
    if (modulesOrder.length > 0 && modulesOrder.length !== course.modules.length) {
      ErrorLogger.captureEvent(
        "Lessons contain module that's not included in the course",
        'warning',
        { modulesCount: modulesOrder.length, courseModulesCount: course.modules.length }
      );
    }

    const courseModules = modulesOrder
      .map(modId => {
        const courseModule = course.modules.find(cMod => cMod.id === modId);
        if (!courseModule) {
          ErrorLogger.captureEvent(
            "Lessons contain module that's not included in the course",
            'warning',
            { moduleId: modId }
          );
          return null;
        }
        return courseModule;
      })
      // We filter null results... this interface of ordering modules is really week
      // A very poor design... I can foresee this code breaking...
      // I am adding extra console logs to make sure that if it stops working as expected
      // we will get notified...
      .filter(x => !!x) as ModuleType[];

    courseModules.forEach(mod => {
      const moduleLessons = lessons.filter(l => l.moduleId === mod.id);

      result[mod.id] = {
        module: mod,
        lessons: moduleLessons
      };
    });

    return result;
  }
);

const getOfflineDownloadStatus = (lessonId: string) => (state: LxStoreState) => {
  return state.offline.status[lessonId];
};

const isLoading = (state: LxStoreState) => {
  return state.courseware.isLoading;
};

const getFirstIncompleteLessonId = (lessonIds: string[]) => (s: LxStoreState) => {
  return lessonIds.find(id => !getLessonProgress(id, s)?.completed);
};

const hasStarsEnabled = (lesson: LessonSummaryType | LessonType) => (state: LxStoreState) => {
  return UserSelectors.hasStarsEnabled(state.user) && lesson.features.areStarsEnabled;
};

const isScored = (lesson: LessonType) => {
  return lesson.isScoringEnabled && lesson.configuration.styleConfiguration.minimumScore > 0;
};

const hasInteractionSummary = (lessonId: string) => (state: LxStoreState) => {
  const isInProgress = getIsLessonInProgress(lessonId)(state);
  if (isInProgress) {
    return false;
  }

  const lesson = getLesson(lessonId, state);
  return !lesson?.configuration.styleConfiguration.disableInteractionSummary ?? false;
};

export const LessonSelectors = {
  getLesson,
  getLessonProgress,
  getLessons,
  getLessonsProgress,
  getLessonAttempt,
  getIsLessonInProgress,
  getCourseCompletion,
  getNextAccessibleLessonFromCurrentLessonId,
  isLessonAvailable,
  isLocked,
  isLockedToday,
  isLockedFromLastAttempt,
  isLockedFromCompletion,
  getLessonStatus,
  getLessonCompletionStatus,
  getProgressToCompletionStatus,
  getLessonPrerequisites,
  getFetchErrorCode,
  getNewScoreRankings,
  getLessonCourseId,
  getLessonCourse,
  getInteractionhSummaryItem,
  getTitle,
  getDescription,
  getTextColor,
  getUnlockPayloadFromPrerequisites,
  getLessonSlides,
  getFirstSlideId,
  getLessonMinimumScore,
  getIsSummaryDialogOpen,
  getLessonSummariesWithModules,
  getLessonSummariesWithoutModules,
  getMemoizedLessonsSummariesWithProgress,
  getMemoizedModules,
  getOfflineDownloadStatus,
  isLoading,
  getFirstIncompleteLessonId,
  hasStarsEnabled,
  isScored,
  hasInteractionSummary,
  getCanLessonBeReviewed,
  findLessonSummary
};
