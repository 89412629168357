import type { SCUITheme } from '@edapp/sc-web-ui';
import { themeApp as AppTheme } from '@edapp/themes';
import { pxToRem } from '@edapp/themes/src/common/utils';
import { Platform } from '@maggie/cordova/platform';
import { AppLayoutSelectors } from '@maggie/layout/selectors';
import { getLocationPath } from '@maggie/store/navigation/utils';
import { globalZIndex } from '@maggie/theme/globalZIndex';

import colors from './colors';
import { Screens } from './screens';

// This comes from learners-app/client/app/sass -> $nav-bar-height
const NAVBAR_HEIGHT_VALUE = 48;

/**
 * When using this, be mindful of `safe-area-inset-top`
 *
 * https://webkit.org/blog/7929/designing-websites-for-iphone-x/
 */
const NAVBAR_HEIGHT = Platform.isMobile()
  ? // Mobile - only navy top bar
    NAVBAR_HEIGHT_VALUE
  : // Desktop - navy top bar + white bar + padding top and bottom
    NAVBAR_HEIGHT_VALUE + 8 * 2;

export const Theme = {
  ...AppTheme,
  screens: Screens,
  breakpoints: {
    ...AppTheme.breakpoints,
    /**
     * Make sure css `sm` breakpoint aligns with isMobile.
     */
    get sm(): number {
      const path = getLocationPath();
      const routeName = window.__router?.['hashManager'].findMatch(path)?.routeName;
      const breakpoint = AppLayoutSelectors.getLayoutBreakpoint(routeName);
      if (breakpoint > 0) {
        return pxToRem(breakpoint);
      } else {
        return pxToRem(744);
      }
    }
  },
  colors: {
    ...AppTheme.colors,
    ...colors
  },
  zIndex: globalZIndex,
  
  // The toolbar size of the main in the split view layout
  // height
  toolbarHeight: 44,

  topContainerHeight: NAVBAR_HEIGHT_VALUE,
  getNavbarHeight: () => NAVBAR_HEIGHT,
  // This is the breakpoint for desktop - where things should be centered
  screenMaxWidth: 1008,
  homeScreenMaxWidth: 1440
};

export type ThemeType = typeof Theme & SCUITheme;
export default Theme;
